import { createApi } from "@reduxjs/toolkit/query/react";

import type { AIAssistantJobResult, BaseJob } from "@/src/components/AIAssistant/types";
import {
  API_ENDPOINT_AI_ASSISTANT_GET_JOB,
  API_ENDPOINT_AI_ASSISTANT_GET_JOBS,
  API_ENDPOINT_AI_ASSISTANT_SUBMIT_GENERATED_RESULT,
} from "@/src/constants/AppConstant";
import type { FetchArgs } from "@/src/core/AxiosRTKQuery";
import axiosBaseQuery from "@/src/core/AxiosRTKQuery";

type GetJobsResponse = {
  jobs: BaseJob[];
  status: number;
};

type GetJobsRequest = {
  spaceId: number;
  pageToken?: string;
};

type GetJobResponse = {
  job: AIAssistantJobResult;
  status: number;
};

type GetJobRequest = {
  jobId: string;
};

export interface SubmitGeneratedRequest {
  jobId: string;
  resultId: string;
  valueIds: string[];
}
export const aiAssistantJobsAPI = createApi({
  reducerPath: "aiAssistant",
  baseQuery: axiosBaseQuery,
  endpoints: builder => ({
    getAIAssistantJobs: builder.query<GetJobsResponse, GetJobsRequest>({
      query: (args): FetchArgs => ({
        url: API_ENDPOINT_AI_ASSISTANT_GET_JOBS,
        method: "POST",
        data: args,
      }),
    }),
    getAIAssistantJob: builder.query<GetJobResponse, GetJobRequest>({
      query: (args): FetchArgs => ({
        url: API_ENDPOINT_AI_ASSISTANT_GET_JOB,
        method: "POST",
        data: args,
      }),
    }),
    submitGeneratedResults: builder.mutation<{}, SubmitGeneratedRequest>({
      query: (args): FetchArgs => ({
        url: API_ENDPOINT_AI_ASSISTANT_SUBMIT_GENERATED_RESULT,
        method: "POST",
        data: {
          ...args,
        },
      }),
    }),
  }),
});

export const {
  useGetAIAssistantJobsQuery,
  useLazyGetAIAssistantJobsQuery,
  useGetAIAssistantJobQuery,
  useSubmitGeneratedResultsMutation,
} = aiAssistantJobsAPI;
