import type { DeepPartial } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import merge from "lodash.merge";

import type { GetSpaceByUrlResponse } from "@/src/domains/content/types/ContentAPITypes";
import type { Stats } from "@/src/domains/space/components/SpaceListSidebar/types/Space";
import type { SpaceCanonicalId, SpaceId } from "@/src/domains/space/models/types";

export type SpaceData = Partial<GetSpaceByUrlResponse> & {
  lastSyncSource: "SPACE_BY_URL" | "SPACE_DOCK" | "SPACE_HOMEPAGE" | "MEMBER_LIST" | "COACHMARK";
};

export type SpaceState = {
  /**
   * GetSpaceByUrlResponse should always be a partial
   * since not all fields are available on some of the API endpoints
   */
  spaces: { [spaceId: SpaceId]: SpaceData };
  spaceIdMap: { [spaceUrl: SpaceCanonicalId]: SpaceId };
};

const initialState: SpaceState = {
  spaces: {},
  spaceIdMap: {},
};

export type SpaceDataPayload = {
  spaceData: DeepPartial<GetSpaceByUrlResponse>;
  source: "SPACE_BY_URL" | "SPACE_DOCK" | "SPACE_HOMEPAGE" | "MEMBER_LIST" | "COACHMARK";
};

export type SpaceStatsPayload = {
  spaceUrl: string;
  spaceStats: DeepPartial<GetSpaceByUrlResponse["contentSpaceStats"]>;
};

const SOURCE_PRIORITY = {
  SPACE_BY_URL: 0,
  SPACE_DOCK: 1,
  SPACE_HOMEPAGE: 2,
  MEMBER_LIST: 3,
  // -1 is for updates that can override the SPACE_BY_URL source data
  COACHMARK: -1,
};

const spaceDataSlice = createSlice({
  name: "spaceData",
  initialState,
  reducers: {
    setSpaceData: (state, { payload }: { payload: SpaceDataPayload }) => {
      // SPACE_BY_URL has the highest priority.
      // So if we have a space data from SPACE_BY_URL, we should not override it with SPACE_DOCK or SPACE_HOMEPAGE.
      const spaceId = payload.spaceData.contentSpace?.spaceId;
      if (!spaceId) {
        return;
      }
      if (payload.source === "SPACE_BY_URL") {
        state.spaces[spaceId] = {
          ...payload.spaceData,
          lastSyncSource: payload.source,
        } as SpaceData;
      } else if (SOURCE_PRIORITY[payload.source] < 0) {
        // If the source is COACHMARK, we should only update the coachmark field
        const data = merge(state.spaces[spaceId] || {}, payload.spaceData);
        state.spaces[spaceId] = { ...data, lastSyncSource: payload.source };
      } else if (SOURCE_PRIORITY[payload.source] < SOURCE_PRIORITY[state.spaces[spaceId]?.lastSyncSource]) {
        const data = merge(state.spaces[spaceId] || {}, payload.spaceData);
        state.spaces[spaceId] = { ...data, lastSyncSource: payload.source };
      }
      if (payload.spaceData?.contentSpace?.spaceId && payload.spaceData.contentSpace.spaceUrl) {
        state.spaceIdMap[payload.spaceData.contentSpace.spaceUrl] = payload.spaceData.contentSpace.spaceId;
      }
    },
    // Only updates the space stats such as watchCount, onlineMemberCount, etc.
    setSpaceStats: (state, { payload }: { payload: SpaceStatsPayload }) => {
      const spaceId = state.spaceIdMap[payload.spaceUrl];
      if (!spaceId) {
        return;
      }
      state.spaces[spaceId] = {
        ...(state.spaces[spaceId] || {}),
        contentSpaceStats: {
          ...(state.spaces[spaceId]?.contentSpaceStats || {}),
          ...payload.spaceStats,
        } as Stats,
      };
    },
    setMultipleSpaceData: (state, { payload }: { payload: SpaceDataPayload[] }) => {
      payload.forEach(({ spaceData, source }) => {
        const spaceId = spaceData.contentSpace?.spaceId;
        if (!spaceId) {
          return;
        }
        if (state.spaces[spaceId]?.lastSyncSource !== "SPACE_BY_URL") {
          // We merge the existing space data with the new space data to ensure that we don't lose any fields
          const data = merge(state.spaces[spaceId] || {}, spaceData);
          state.spaces[spaceId] = { ...data, lastSyncSource: source };
        }
        if (spaceData?.contentSpace?.spaceId && spaceData.contentSpace.spaceUrl) {
          state.spaceIdMap[spaceData.contentSpace.spaceUrl] = spaceData.contentSpace.spaceId;
        }
      });
    },
  },
});

export const { setSpaceData, setMultipleSpaceData, setSpaceStats } = spaceDataSlice.actions;

export const createSpaceDataSelector = (spaceUrl?: string) => {
  return <T>(selector: (spaceData: Partial<GetSpaceByUrlResponse>) => T) =>
    ({ spaceData }: { spaceData: SpaceState }) => {
      if (!spaceUrl) return selector(spaceData.spaces);

      const spaceId = spaceData.spaceIdMap[spaceUrl];
      if (!spaceData?.spaces[spaceId]) return selector({});
      return selector(spaceData.spaces[spaceId]);
    };
};

export const getSpaceIdBySpaceUrlSelector = (spaceUrl?: string) => {
  return (state: { spaceData: SpaceState }) => {
    if (!spaceUrl) return undefined;
    return state.spaceData.spaceIdMap[spaceUrl];
  };
};

export const getSpaceURLBySpaceIdSelector = (spaceId?: number) => (state: { spaceData: SpaceState }) => {
  if (!spaceId) return undefined;
  return state.spaceData.spaces?.[spaceId]?.contentSpace?.spaceUrl;
};

export default spaceDataSlice;
