import { MAIN_TEXT_AREA, ROUTER_PATH_SPACE_NODE, SPACE_PRESENTATION } from "@/src/constants/AppConstant";
import { FORMFIELD_FIXED_ORDER_PREV_ID } from "@/src/constants/ContentConstants";
import type {
  TocBreadcrumbPath,
  TocBreadcrumbPathType,
} from "@/src/domains/content/components/Toc/TocBreadcrumb/TocBreadcrumb";
import type NodeResponseModel from "@/src/domains/content/models/node/NodeResponseModel";
import type { Action, NodeBreadcrumb } from "@/src/domains/content/types/Node";
import type {
  DynamicFormConfig,
  DynamicFormTextAreaValues,
  DynamicFormValues,
} from "@/src/domains/dynamic-form/types/DynamicForm";
import type { MultimediaSpec, SourceSpec } from "@/src/domains/dynamic-form/types/FormValues";
import parseTemplateString from "@/src/utils/helpers/parseTemplateString";

interface CustomFormObj {
  response: {
    [key: string]: {
      data: {
        value: string;
        textAreaValue?: {
          value?: string;
        };
        tabValue?: string;
        multimediaSpec: MultimediaSpec[];
        sourceSpec: SourceSpec[];
      };
      type: string;
    };
  };
  responseSpec: { isDraft: false };
}

export const getIndexDraftResponseTrays = (responseTray: NodeResponseModel[]) => {
  if (!responseTray) {
    return null;
  }
  const isDraftExist = responseTray
    ?.filter(item => item !== undefined)
    .find(({ draftMetadata }) => draftMetadata?.lastUpdatedAt !== undefined);

  if (isDraftExist) {
    return responseTray.findIndex(
      ({ id, draftMetadata }) => id === isDraftExist.id && draftMetadata?.lastUpdatedAt !== undefined,
    );
  }
  return -1;
};

export const transformCustomFormToObj = (customForm: string) => {
  if (!customForm) return null;
  let customFormObj: CustomFormObj | null = null;
  try {
    customFormObj = JSON.parse(customForm);
  } catch (e) {
    return null;
  }
  return customFormObj;
};

export const getDraftTextFromCustomForm = (customForm: string) => {
  const customFormObj = transformCustomFormToObj(customForm);
  return customFormObj?.response?.[MAIN_TEXT_AREA]?.data?.textAreaValue?.value;
};

export const getMainContentFromFormValues = (values: DynamicFormValues["values"]) => {
  // TODO fix the typing of `DynamicFormValues` there is a possibility textField.data is undefined
  const textField = values[MAIN_TEXT_AREA];

  if (!textField) return "";
  const isTextArea = textField?.data && Object.prototype.hasOwnProperty.call(textField.data, "textAreaValue");

  if (isTextArea) {
    return (textField as DynamicFormTextAreaValues).data.textAreaValue?.value || "";
  }
  return String(values[MAIN_TEXT_AREA]?.data?.value) || "";
};

export function parseDraftBreadcrumb(breadcrumb: NodeBreadcrumb[]): TocBreadcrumbPath[] {
  return breadcrumb.map(({ id, text, breadcrumbType, sectionPresetId }) => ({
    id: Number(id),
    text,
    type: breadcrumbType as TocBreadcrumbPathType,
    sectionType: sectionPresetId,
  }));
}

// Generate url for discussion view
export const generateDiscussionViewUrl = (spaceId: string, nodeId: string, slug: string) => {
  const url = parseTemplateString(ROUTER_PATH_SPACE_NODE, {
    spaceId,
    nodeId,
    slug,
    presentation: SPACE_PRESENTATION.ALL,
  });
  return url;
};

// Generate state for discussion view
export const generateDiscussionViewExtraState = (
  nodeId: string,
  parentId: string,
  selectedActionId: string,
  pageSource?: string,
) => {
  return {
    clickedNodeId: nodeId,
    discussionViewChildId: nodeId,
    discussionViewNodeId: parentId,
    discussionViewPageSource: pageSource,
    selectedActionId,
  };
};

// param comes from dynamic form `value.values` which had no clear definition
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateDraftDataFromDynamicForm = (values: any) => {
  const defaultValueObject: Action["defaultValues"] = {};
  const dynamicFormValueKeys = Object.keys(values);
  for (let i = 0; i < dynamicFormValueKeys.length; i++) {
    const fieldKey = dynamicFormValueKeys[i];
    defaultValueObject[fieldKey] = values[fieldKey].data;
  }
  return defaultValueObject;
};

/** Injecting anchor id to submit floating draft containing wiki add in between nodes */
export function injectAnchorIdToWikiDraft(actionForm: DynamicFormConfig, wikiAnchorNodeId: string) {
  if (actionForm.cList) {
    if (actionForm?.cList) {
      const orderingFieldIndex = actionForm.cList.findIndex(field => field.cid === FORMFIELD_FIXED_ORDER_PREV_ID);

      const returnedCList = [];
      for (let i = 0; i < actionForm.cList.length; i++) {
        if (i === orderingFieldIndex) {
          returnedCList[i] = {
            ...actionForm.cList[i],
            defaultVal: { value: wikiAnchorNodeId },
          };
        } else {
          returnedCList[i] = actionForm.cList[i];
        }
      }
      return { ...actionForm, cList: returnedCList };
    } else {
      return actionForm;
    }
  }
  return actionForm;
}
