import { RESOLUTION_ACTIONS_WITH_DIALOG } from "@/src/domains/content/components/Actions/helpers/ResolutionActionHelper";
import { MORE_ACTIONS, NODE_ACTION_TYPE } from "@/src/domains/types/NodeActionType";

export const NODE_ANCESTOR_ID = "0";

export const NODE_TYPE_UNKNOWN = "UNKNOWN";
export const NODE_TYPE_PARENT = "PARENT";
export const NODE_TYPE_NODE = "NODE";
export const NODE_TYPE_GROUP = "GROUP";
export const NODE_TYPE_SUBGROUP = "SUBGROUP";
export const NODE_TYPE_ACTION_NODE = "ACTION_NODE";

export const NODE_RESPONSE_TYPE_ITEM = "ITEM"; // normal menu item
export const NODE_RESPONSE_TYPE_GROUP = "GROUP"; // cascading menu item
export const NODE_RESPONSE_TYPE_GROUP_EXPAND = "GROUP_EXPAND"; // new action flow
export const NODE_RESPONSE_TYPES = [NODE_RESPONSE_TYPE_ITEM, NODE_RESPONSE_TYPE_GROUP, NODE_RESPONSE_TYPE_GROUP_EXPAND];

export const NODE_POD_TYPE_NONE = "NONE";
export const NODE_POD_TYPE_POLL = "POLL";
export const NODE_POD_TYPE_CLAIM = "CLAIM";
export const NODE_POD_TYPE_QUESTION = "QUESTION";
export const NODE_POD_TYPE_HOW_TO = "HOW_TO";
export const NODE_POD_TYPE_OBJECT = "OBJECT";
export const NODE_POD_TYPE_CONTEXT = "CONTEXT";
export const NODE_POD_TYPE_SECTION = "SECTION";
export const NODE_POD_TYPE_SOURCE = "SOURCE";
export const NODE_POD_TYPE_COMMENT = "COMMENT";
export const NODE_POD_TYPE_MULTIMEDIA = "MULTIMEDIA";
export const NODE_POD_TYPE_SOCIAL = "SOCIAL";
export const NODE_POD_TYPE_CHANNEL = "CHANNEL";
export const NODE_POD_TYPE_ANSWER = "ANSWER";
export const NODE_POD_TYPE_PROPOSITION_QUESTION = "PROPOSITION_QUESTION";
export const NODE_POD_TYPE_PROPOSITION_CLAIM = "PROPOSITION_CLAIM";
export const NODE_POD_TYPE_INFORMATIONAL = "INFORMATIONAL";
export const NODE_PODR_TYPE_COMMENT = "COMMENT";
export const NODE_PODR_TYPE_NONE = "NONE";
export const NODE_PODR_TYPE_REPLY = "REPLY";
export const NODE_PODR_TYPE_CONCERNING = "CONCERNING";
export const NODE_PODR_TYPE_PRO_ARGUMENT = "PRO_ARGUMENT";
export const NODE_PODR_TYPE_CONS_ARGUMENT = "CON_ARGUMENT";
export const NODE_PODR_TYPE_PRO_SOURCE = "PRO_SOURCE";
export const NODE_PODR_TYPE_CON_SOURCE = "CON_SOURCE";
export const NODE_PODR_TYPE_INSTANCEOF = "INSTANCEOF";
export const NODE_PODR_TYPE_REFINEMENT = "REFINEMENT";
export const NODE_PODR_TYPE_DUPLICATE = "DELEGATED";
export const NODE_PODR_TYPE_OBSOLETE = "REDIRECT";
export const NODE_PODR_TYPE_ATTACHMENT = "ATTACHMENT";
export const NODE_PODR_TYPE_CONTEXT_SOURCE = "CONTEXT_SOURCE";
export const NODE_PODR_TYPE_ANSWER = "ANSWER";
export const NODE_PODR_TYPE_FEEDBACK = "FEEDBACK";
export const NODE_PODR_TYPE_FEEDBACK_MAJOR = "FEEDBACK_MAJOR";
export const NODE_PODR_TYPE_FEEDBACK_OPEN = "OPEN_FEEDBACK";
export const NODE_PODR_TYPE_FEEDBACK_CLOSED = "CLOSED_FEEDBACK";
export const NODE_PODR_TYPE_EXTRACTED = "EXTRACTED";
export const NODE_PODR_TYPE_IRRELEVANCE = "IRRELEVANCE";
export const NODE_PODR_TYPE_INVALIDATION_FLAWED_PREMISE = "INVALIDATION_FLAWED_PREMISE";

export const SUGGESTION_LABEL = "SUGGESTION";

export const NODE_POST_TYPE_OPEN_FEEDBACK = "OPEN_FEEDBACK";

export const NON_CHILD_NODE_POD = [NODE_POD_TYPE_MULTIMEDIA, NODE_POD_TYPE_SOURCE];
export const NON_CHILD_NODE_PODR = [NODE_PODR_TYPE_ATTACHMENT, NODE_PODR_TYPE_DUPLICATE, NODE_PODR_TYPE_OBSOLETE];

//discussion map
export const DISCUSSION_MAP_COLLAPSE_ACTION = "collapse";
export const DISCUSSION_MAP_EXPAND_ACTION = "expand";
export const DISCUSSION_MAP_STRUCTURE_TYPE_BLOCK = "BLOCK";
export const DISCUSSION_MAP_STRUCTURE_TYPE_DIVISION = "DIVISION";
export const DISCUSSION_MAP_STRUCTURE_TYPE_SUBDIVISION = "SUBDIVISION";

export const HIGHLIGHT_QUERY_STRING = "highlight";

export const PREVIEW_POST_TYPE_FEEDBACK = "FEEDBACK-REVIEWABLE";

export const AUTO_SUBMIT_ACTIONS: string[] = [MORE_ACTIONS.FOLLOW_CONTENT, MORE_ACTIONS.UNFOLLOW_CONTENT];

export const GROUP_FILTER = {
  DISCOURSE: ["FORUM"],
  INFORMATIONAL: ["WIKI"],
  CHANNEL: ["CHANNEL"],
  POLL: ["POLL"],
};

export const CARD_LOADING_PLACEHOLDER = "LoadingCardPlaceholder";
export const OPTIMISTIC_ADD_NODE_ID = "10001-OPTIMISTIC";

export const EVENT_TRIGGER_FEED_REFRESH = "triggerFeedRefresh";
export const EVENT_TRIGGER_DISCUSSION_VEW_REFRESH_FAB = "triggerDiscussionViewRefreshFab";

export const ACTIONS_RENDERED_WITH_DIALOG: string[] = [
  MORE_ACTIONS.MARK_OBSOLETE,
  MORE_ACTIONS.MARK_NOT_OBSOLETE,
  MORE_ACTIONS.MARK_DUPLICATE,
  MORE_ACTIONS.MARK_NOT_DUPLICATE,
  MORE_ACTIONS.EDIT_POST_TYPE,
  MORE_ACTIONS.DELETE,
  MORE_ACTIONS.MOVE_PARENT,
  MORE_ACTIONS.MOVE_REPLY,
  MORE_ACTIONS.FLAG_NODE,
  MORE_ACTIONS.REJECT,
  MORE_ACTIONS.REJECT_FEEDBACK,
  MORE_ACTIONS.SET_USER_ANONYMOUS,
  MORE_ACTIONS.SET_USER_PUBLIC,
  MORE_ACTIONS.EDIT_DUPLICATE_LINK,
  MORE_ACTIONS.EDIT_OBSOLETE_LINK,
  MORE_ACTIONS.REPORT,
  MORE_ACTIONS.MENTION_USER,
  MORE_ACTIONS.VIEW_SECTION_HISTORY,
];

export const FORMFIELD_FIXED_ORDER_PREV_ID = "FIXED_ORDER_PREV_ID";

// The `cid` of the anonymity dropdown component in the action form
// This is used to automatically adjust the channel new post form top row with avatar
export const AUTHOR_VISIBILITY_OPTION_ID = "USER_VISIBILITY_OPTION";
export const AUTHOR_VISIBILITY_OPTION_ANONYMOUS = "ANONYMOUS";

export const ENABLE_FEED_TYPE_FILTER = false;

export const SEEKING_TAG = {
  NEEDS_PERSPECTIVE: "NEEDS_PERSPECTIVE",
  NEEDS_TOPIC_COVERAGE: "NEEDS_TOPIC_COVERAGE",
};

export const SECTION_TAG = {
  ...SEEKING_TAG,
  NEEDS_CLARITY: "NEEDS_CLARITY",
  NEEDS_RELOCATING: "NEEDS_RELOCATING",
  NEEDS_RESTRUCTURING: "NEEDS_RESTRUCTURING",
  NOT_RELEVANT: "NOT_RELEVANT",
};

// Sample content label is currently being rendered as a code
export const SAMPLE_CONTENT_TEXT = "sample content"; // use lowercase for comparison
export const SAMPLE_CONTENT_LABEL = `<code>${SAMPLE_CONTENT_TEXT}</code>`;

export const SECTION_TAG_FIELD = "SECTION_TAG";

export const SUFFIX_CLOSED_FEEDBACK = "CLOSED_FEEDBACK";
export const SUFFIX_OPEN_FEEDBACK = "OPEN_FEEDBACK";
export const SUFFIX_SUPPORT = "SUPPORTING_ARG";
export const SUFFIX_ANSWER = "ANSWER";
export const SUFFIX_TOPIC_CHILD = "DISCUSS";

export const CHILD_PREVIEW_SUFFIX_GROUP = [
  SUFFIX_CLOSED_FEEDBACK,
  SUFFIX_OPEN_FEEDBACK,
  SUFFIX_SUPPORT,
  SUFFIX_ANSWER,
  SUFFIX_TOPIC_CHILD,
];

export const SHORT_NODE_TEXT_LENGTH = 100;

export const CUSTOM_TOOLTIP_LABEL: Record<string, string> = {
  [MORE_ACTIONS.RETRACT]: "nodeResolutionActionRenderer.retractTooltip",
  [MORE_ACTIONS.RETRACT_FEEDBACK]: "nodeResolutionActionRenderer.retractTooltip",
};

export const NODE_CLOSURE_STATE = {
  OPEN: "OPEN",
  RESOLVED_FEEDBACK: "RESOLVED_FEEDBACK",
  RESOLVED: "RESOLVED",
  ADDRESSED: "ADDRESSED",
};

export const DISCUSSION_PAGINATION_CHILDREN_COUNT = 8;

export const ACTIONS_RENDERED_WITHOUT_PREVIEW = [
  ...ACTIONS_RENDERED_WITH_DIALOG,
  ...RESOLUTION_ACTIONS_WITH_DIALOG,
  MORE_ACTIONS.SET_USER_ANONYMOUS,
  MORE_ACTIONS.SET_USER_PUBLIC,
];

// list down the actions in which we want to show the current node text at the top of the dialog
export const ACTIONS_WITH_NODE_TEXT_DIALOG_HEADER: string[] = [
  MORE_ACTIONS.MOVE_PARENT,
  MORE_ACTIONS.MOVE_REPLY,
  MORE_ACTIONS.MARK_OBSOLETE,
  MORE_ACTIONS.MARK_DUPLICATE,
  MORE_ACTIONS.EDIT_POST_TYPE,
  MORE_ACTIONS.REPLY_TO_POST,
  NODE_ACTION_TYPE.DISMISS,
  NODE_ACTION_TYPE.ACCEPT,
  NODE_ACTION_TYPE.REJECT,
  NODE_ACTION_TYPE.REJECT_FEEDBACK,
  NODE_ACTION_TYPE.CLOSE,
];

export const ACTION_RANKING_SCORES = {
  [MORE_ACTIONS.MARK_READ]: 0.05,
  [MORE_ACTIONS.VIEW_HISTORY]: 1.2,
  [MORE_ACTIONS.SHARE_NODE]: 0.3,
  [MORE_ACTIONS.ADD_RESPONSE]: 1.0,
  [MORE_ACTIONS.EDIT_POST]: 1.1,
  [MORE_ACTIONS.CLOSURE_GROUP]: 4.1,
  [MORE_ACTIONS.VIEW_SECTION_HISTORY]: 2.5,
};
