import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { PERSIST as persistActionType, persistStore } from "redux-persist";

import { aiAssistantJobsAPI } from "@/src/services/aiAssistant";
import { contentNodeAPI } from "@/src/services/contentApi";
import authenticateLegacyAxios from "@/src/stores/middlewares/authenticateLegacyAxios";
import notificationMiddleware from "@/src/stores/middlewares/notificationMiddleware";
import userLogoutMiddleware from "@/src/stores/middlewares/userLogoutMiddleware";
import createReducerManager from "@/src/stores/reducerManager";
import rootReducer from "@/src/stores/rootReducer";

function createStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => {
      const defaultMiddleware = getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [persistActionType],
          ignoredPaths: ["dynamic.activeNodeDetail.nodeId"],
        },
        immutableCheck: {
          ignoredPaths: ["spaceSectionContentCache"],
        },
      });

      return defaultMiddleware.concat(
        aiAssistantJobsAPI.middleware,
        contentNodeAPI.middleware,
        authenticateLegacyAxios,
        userLogoutMiddleware,
        notificationMiddleware,
      );
    },
  });

  return store;
}

export const store = createStore();

setupListeners(store.dispatch);
export const reducerManager = createReducerManager(store.replaceReducer);
export const persistor = persistStore(store);

export default createStore;
