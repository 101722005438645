import { createApi } from "@reduxjs/toolkit/query/react";

import { API_ENDPOINT_CONTENT_GET_NODES } from "@/src/constants/AppConstant";
import type { FetchArgs } from "@/src/core/AxiosRTKQuery";
import axiosBaseQuery from "@/src/core/AxiosRTKQuery";
import type { GetContentNodesResponse } from "@/src/domains/content/types/ContentAPITypes";

interface GetContentNodesArgs {
  spaceId: number;
  nodeIds: string[];
  shouldHighlight?: boolean;
  includeMainParent?: boolean;
  excludeNonEmbeddedChildren?: boolean;
}

export const contentNodeAPI = createApi({
  reducerPath: "contentNodeAPI",
  baseQuery: axiosBaseQuery,
  endpoints: builder => ({
    getContentNodes: builder.query<GetContentNodesResponse, GetContentNodesArgs>({
      query: (args): FetchArgs => ({
        url: API_ENDPOINT_CONTENT_GET_NODES,
        method: "POST",
        data: { ...args },
      }),
    }),
  }),
});

export const { useGetContentNodesQuery } = contentNodeAPI;
