import type { BaseQueryFn, FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import type { AxiosError, AxiosResponse, Method } from "axios";

import { APP_VERSION } from "@/src/constants/AppConstant";
import axios from "@/src/core/AxiosFetcher";
import { generateHeaders, getDeviceContext } from "@/src/hooks/helpers/apiHelpers";
import type { RootState } from "@/src/stores/rootReducer";
import { getAPIBaseUrl } from "@/src/utils/APIUtil";

export type FetchArgs = {
  url: string;
  method: Method;
  data: Record<string, unknown>;
};

const axiosBaseQuery: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (
  args: FetchArgs,
  api: { getState: () => unknown },
): Promise<{ data: unknown } | { error: FetchBaseQueryError }> => {
  const state = api.getState() as RootState;
  const accessToken = state.user.accessToken;

  try {
    const result: AxiosResponse = await axios({
      url: args.url,
      method: (args.method || "POST") as Method,
      headers: generateHeaders(accessToken, false, {}),
      data: {
        deviceContext: getDeviceContext(),
        frontendVersion: APP_VERSION,
        ...args.data,
      },
      baseURL: getAPIBaseUrl(),
    });

    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: {
        error: err.message,
        status: err.response?.status || "CUSTOM_ERROR",
        data: err.response?.data || err.message,
      },
    };
  }
};

export default axiosBaseQuery;
